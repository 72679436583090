<template>
  <div v-if="Object.keys(mobileMenuData).length > 0" class="mobile-menu">
    <div class="menu">
      <div v-if="mainMenuActive" class="Menu_container">
        <div class="menu_title">{{ mobileMenuData.title }}</div>
        <ul>
          <li @click="toggleProfileMenu()">
            <img
              class="button settings"
              src="/images/settings-menu/profileico.svg"
            />
            {{ mobileMenuData.profile.title }}
          </li>
          <li @click="toggleLangMenu()">
            <img
              class="button settings"
              src="/images/settings-menu/langoptionico.svg"
            />
            {{ mobileMenuData.language.title }}
          </li>
          <li @click="enterVR()">
            <img
              class="button settings"
              src="/images/settings-menu/entervrico.svg"
            />
            {{ mobileMenuData.enterVR }}
          </li>
          <li v-if="showUnsubBUtton" @click="unsubscribe()">
            <img
              class="button settings"
              src="/images/settings-menu/unsubscribe.svg"
            />
            {{ mobileMenuData.unsubscribe.title }}
          </li>
          <li @click="termsAndConditions()">
            <img
              class="button settings"
              src="/images/settings-menu/termsico.svg"
            />
            {{ mobileMenuData.terms }}
          </li>
          <li v-if="showContactForm" @click="redirectToCustomerCarePage()">
            <img
              class="button settings"
              src="/images/settings-menu/langoptionico.svg"
            />
            {{ mobileMenuData.contact.title }}
          </li>
          <li @click="logout()">
            <img class="button settings" src="/images/settings-menu/exit.svg" />
            {{ mobileMenuData.logout }}
          </li>
        </ul>
      </div>
      <div v-if="profileMenuActive" class="profile_container">
        <div @click="toggleProfileMenu()" class="profile_menu">
          {{ mobileMenuData.title }}
        </div>
        <div class="profile_data">
          <div class="profile_img">
            <img src="/images/settings-menu/avatarico.svg" />
            <p>{{ mobileMenuData.profile.subTitle }}</p>
          </div>
          <label>{{ mobileMenuData.profile.phoneNumber }}</label>
          <input
            type="text"
            name="Mobile Number"
            value="0990 90 90"
            v-model="profile.msisdn"
            disabled
          />
          <div v-if="showSubscriptionEndDate">
            <label>{{ mobileMenuData.profile.endDate }}</label>
            <input
              type="text"
              name="End Date"
              value="2021-10-01"
              v-model="profile.renewalDate"
              disabled
            />
          </div>
        </div>
      </div>
      <div v-if="langMenuActive" class="lang_container">
        <div @click="toggleLangMenu()" class="lang_menu">
          {{ mobileMenuData.title }}
        </div>
        <div class="lang_title">
          {{ mobileMenuData.language.subTitle }}
        </div>
        <ul>
          <li
            v-for="language in availableLanguages"
            :class="{ selected: language.id === selectedLanguage }"
            v-bind:key="language.id"
            @click="selectLanguage(language)"
          >
            <img
              v-if="language.id === selectedLanguage"
              src="/images/settings-menu/langselection.svg"
            />
            <span :class="{ active_lang: language.id === selectedLanguage }">{{
              language.value
            }}</span>
          </li>
        </ul>
      </div>
      <!-- <div v-if="contactUsMenuActive" class="lang_container">
        <div @click="toggleContactMenu()" class="profile_menu">
          {{ mobileMenuData.title }}
        </div>
        <div class="lang_title">
          {{ mobileMenuData.contact.title }}
        </div>
        <div class="Unsub_info">
          <label>{{ mobileMenuData.contact.phoneNumber }} : 080000019051</label>
          <p>
            <label
              >{{ mobileMenuData.contact.email }} : deservices@cchelp.co</label
            >
          </p>
        </div>
      </div> -->
      <div v-if="UnsubMenuActive" class="Unsub_container">
        <div @click="unsubscribe()" class="Unsub_menu">
          {{ mobileMenuData.title }}
        </div>
        <div class="Unsub_data">
          <img src="/images/settings-menu/avatarico.svg" />
          <p>{{ mobileMenuData.unsubscribe.title }}</p>
        </div>
        <div class="Unsub_info">
          <p>
            {{ mobileMenuData.unsubscribe.unsubInfo }}
          </p>
          <div class="Unsub_btn" @click="redirectToUnsubPage()">
            <span>{{ mobileMenuData.unsubscribe.title }}</span>
          </div>
        </div>
      </div>
      <div @click="closeDialogBox()" class="close-button">
        <img src="/images/ui/exit-button.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { getLoginURL, redirectToLogin } from "../utils/redirect.utils.js";
import { webstore } from "../helpers/auth-helpers.js";
import { getGenericData, transitionTo } from "../utils/aframe.utils";
import { getUnsubLink } from "../utils/unsub.utils";
import {
  getSelectedLanguage,
  setSelectedLanguage,
} from "../../aframe/utils/local-storage.utils";
import { sendOnClick } from "../utils/dataCollection.utils.js";
import { config } from "../helpers/domain-config";

export default {
  props: {},
  data: () => ({
    availableLanguages: [{ id: "en", value: "English" }],
    selectedLanguage: getSelectedLanguage(),
    profileMenuActive: false,
    langMenuActive: false,
    mainMenuActive: true,
    UnsubMenuActive: false,
    contactUsMenuActive: false,
    showContactForm:
      config.subdomain === "de" ||
      config.subdomain === "kw" ||
      config.subdomain === "om",
    showUnsubBUtton: getUnsubLink() !== null,
    profile: {
      msisdn: webstore.getProperty("msisdn"),
      renewalDate: webstore.getProperty("renewalDate"),
    },
    showSubscriptionEndDate: getSelectedLanguage() !== "nl",
    mobileMenuData: {},
  }),

  async created() {
    const { mobileMenu } = await getGenericData();
    this.mobileMenuData = mobileMenu;

    if (["qa", "om", "sa", "ae", "iq", "kw"].indexOf(config.subdomain) >= 0)
      this.availableLanguages.push({ id: "ar", value: "اللغة العربية" });

    if (config.subdomain === "co")
      this.availableLanguages.push({ id: "es", value: "Español" });

    if (config.subdomain === "ca")
      this.availableLanguages.push({ id: "fr", value: "French" });

    if (config.subdomain === "cz")
      this.availableLanguages.push({ id: "cz", value: "Czech" });

    if (config.subdomain === "nl")
      this.availableLanguages.push({ id: "nl", value: "Dutch" });

    if (config.subdomain === "rs")
      this.availableLanguages.push({ id: "rs", value: "Srpski" });

    if (config.subdomain === "es")
      this.availableLanguages.push({ id: "es", value: "Español" });

    if (config.subdomain === "ee")
      this.availableLanguages.push(
        { id: "et", value: "Estonian" },
        { id: "rs", value: "Srpski" }
      );

    if (config.subdomain === "demo")
      this.availableLanguages.push(
        { id: "de", value: "Deutsch" },
        { id: "nl", value: "Dutch" },
        { id: "ar", value: "اللغة العربية" },
        { id: "in", value: "Indonesian" },
        { id: "es", value: "Español" },
        { id: "pl", value: "Polskie" },
        { id: "fr", value: "French" },
        { id: "cz", value: "Czech" },
        { id: "rs", value: "Srpski" },
        { id: "et", value: "Estonian" },
        { id: "vn", value: "Tiếng Việt" },
        { id: "th", value: "Thai" }
      );

    if (config.subdomain === "de")
      this.availableLanguages.push({ id: "de", value: "Deutsch" });

    if (config.subdomain === "th" || config.subdomain === "thd")
      this.availableLanguages.push({ id: "th", value: "Thai" });
  },
  methods: {
    fetchProfileData() {
      if (this.profile.msisdn === null || this.profile.msisdn === "") {
        this.profile.msisdn = webstore.getProperty("msisdn");
        this.profile.renewalDate = webstore.getProperty("renewalDate");
      }
    },

    redirectToCustomerCarePage() {
      const customerCareLink = config.customerCareLink;
      if (customerCareLink) {
        window.open(customerCareLink, "_blank");
      }
    },
    closeDialogBox() {
      this.profileMenuActive = false;
      this.langMenuActive = false;
      this.mainMenuActive = true;
      this.UnsubMenuActive = false;
      this.$emit("close");
    },

    toggleProfileMenu() {
      this.fetchProfileData();
      this.mainMenuActive = !this.mainMenuActive;
      this.profileMenuActive = !this.profileMenuActive;
      if (this.profileMenuActive) {
        document.body.classList.add("mobile-menu-active");
      }
    },

    toggleContactMenu() {
      this.fetchProfileData();
      this.mainMenuActive = !this.mainMenuActive;
      this.contactUsMenuActive = !this.contactUsMenuActive;
      if (this.contactUsMenuActive) {
        document.body.classList.add("mobile-menu-active");
      }
    },

    toggleLangMenu() {
      this.mainMenuActive = !this.mainMenuActive;
      this.langMenuActive = !this.langMenuActive;
      if (this.langMenuActive) {
        document.body.classList.add("mobile-menu-active");
      }
    },

    enterVR: function () {
      this.$emit("close");
      document.querySelector("#enter-vr-button").click();
    },

    unsubscribe: function () {
      this.mainMenuActive = !this.mainMenuActive;
      this.UnsubMenuActive = !this.UnsubMenuActive;
      if (this.UnsubMenuActive) {
        document.body.classList.add("mobile-menu-active");
      }
    },

    redirectToUnsubPage: function () {
      this.$emit("close");
      const redirectUrl = getUnsubLink();
      if (typeof redirectUrl === "string") {
        sendOnClick({ contentName: "optout", redirectUrl });
        window.location.href = redirectUrl;
      }
    },

    logout: function () {
      this.$emit("close");
      sendOnClick({ contentName: "logout", redirectUrl: getLoginURL().href });
      redirectToLogin();
    },

    termsAndConditions: function () {
      this.$emit("close");
      transitionTo(["terms-and-conditions"]);
    },

    selectLanguage: function (language) {
      setSelectedLanguage(language.id);
      window.location.reload();
    },
  },
};
</script>

<style lang="scss">
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  .menu {
    font-family: "Lato", helvetica bold;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20%;
    background-color: black;
    transform: translate(-50%, -50%);
    vertical-align: center;
    pointer-events: all;
    padding: 12px 20px;
    border-radius: 10px;

    @media screen and (device-height: 480px) {
      top: 56% !important;
    }
    ul {
      list-style: none;
      padding: 0;
      width: 100%;
      margin: 1% 0% 0%;
    }

    li {
      display: flex;
      align-items: center;
      padding: 11px 0;
      pointer-events: all;
      cursor: pointer;
      &:last-child {
        margin-bottom: 20px;
      }
      &:hover {
        background-color: rgba(64, 64, 64, 1);
        border-radius: 8px;
      }
      img {
        width: 25px;
        height: 25px;
        padding: 0 18px;
        //transform: translateY(8px);

        &.vr {
          transform: translateY(17px);
        }
      }
    }

    .close-button {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -14px;
      right: -14px;
      border-radius: 15px;
      width: 30px;
      height: 30px;
      text-align: center;
      background-color: white;
      cursor: pointer;
      color: black;
      font-family: "Free Sans Bold";

      img {
        width: 100%;
      }
    }
    .lang_container {
      height: 324px;
      ul {
        height: 72%;
        overflow-y: scroll;
        li {
          justify-content: center;
          font-size: 18px;
          font-weight: 500;
          margin: 0;
          padding: 10px 17px;
          cursor: pointer;

          img {
            position: relative;
            right: 12px;
            width: 15px;
            transform: translateY(-1px);
            padding: 0;
          }
          .active_lang {
            margin-right: 9px;
          }
        }
        .selected {
          color: #2dceef;
        }
        .disabled {
          opacity: 0.2;
        }
      }
      .lang_title {
        text-align: center;
        font-size: 16px;
        margin-top: 7%;
        margin-bottom: 20px;
      }
    }
    .profile_container {
      height: 324px;
      .profile_data {
        .profile_img {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            width: 25%;
          }
        }
      }
      label {
        display: block;
        margin-top: 5%;
        font-size: 11px;
        color: #a5a9a9;
      }
      input {
        font-size: 14px;
        border: none;
        color: #ffffff;
        padding: 3% 0% 2% 0%;
        border-bottom: 1px solid #ffffff;
        background: #000000;
        outline: none;
        width: 100%;
      }
    }
    .Unsub_container {
      height: 324px;
      .Unsub_data {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 25%;
        }
      }
      .Unsub_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 5%;

        .Unsub_btn {
          cursor: pointer;
          background: linear-gradient(
            90deg,
            rgba(45, 239, 224, 1) 0%,
            rgba(45, 155, 239, 1) 57%,
            rgba(53, 237, 251, 1) 100%
          );
          width: 80%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 60px;
          padding: 18px 0px;
          //margin-top: 10%;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .menu {
      width: 30%;
    }
  }
  @media screen and (max-width: 680px) {
    .menu {
      padding: 20px 20px 0px;
      width: 75%;
      .Menu_container {
        margin-left: 20px;

        .menu_title {
          font-size: 19px;
          font-weight: 600;
        }
        ul {
          li {
            font-size: 19px;
            font-weight: 500;
            margin-left: 0;

            img {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
      .lang_container {
        height: 357px;
        .lang_title {
          margin-top: 12%;
          font-size: 16px;
        }
      }
      .profile_container,
      .Unsub_container {
        height: 357px;
      }
      .close-button {
        top: -14px;
        right: -12px;
      }
    }
  }
  @media screen and (max-width: 360px) {
    .menu {
      top: 51%;

      .Menu_container {
        margin-left: 25px;
        ul {
          li {
            font-size: 16px;
          }
        }
      }
      .close-button {
        top: -14px;
        right: -14px;
      }
      .lang_container {
        .lang_menu {
          padding: 0% 8%;
        }
      }
      .profile_container {
        .profile_menu {
          padding: 0 7%;
        }
        .profile_data {
          padding: 7%;
        }
      }
      .Unsub_container {
        .Unsub_menu {
          padding-left: 25px;
        }
        .Unsub_info {
          padding: 0 20px;
        }
      }
    }
  }
  @media screen and (max-width: 825px) and (orientation: landscape) {
    .menu {
      padding: 15px 20px;
      //height: 280px;
      width: 35%;
      margin-top: 3%;

      .close-button {
        top: -15px;
        right: -15px;
      }
      .Menu_container {
        margin-left: 20px;

        .menu_title {
          font-size: 15px;
          font-weight: 600;
        }
        ul {
          margin: 0;
          li {
            padding: 8px 0px;
            font-size: 17px;
            margin-left: 0;
            margin: 0;

            img {
              width: 25px;
              height: 25px;
            }
          }
        }
      }
      .lang_container,
      .Unsub_container {
        height: 264px;
      }
      .profile_container {
        height: 264px;
        .profile_data {
          .profile_img {
            img {
              width: 22%;
            }
          }
        }
      }
      .Unsub_container {
        .Unsub_info {
          p {
            margin: 0;
          }
          .Unsub_btn {
            margin-top: 4%;
            padding: 15px 0px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 668px) and (orientation: landscape) {
    .menu {
      margin-top: 4%;

      .lang_container {
        .lang_menu {
          padding-left: 5%;
        }
      }
      .close-button {
        top: -11px;
      }
    }
  }
  @media screen and (max-width: 568px) and (orientation: landscape) {
    .menu {
      margin-top: 4% !important;
      padding: 6px 20px;
      width: 45%;
      height: 253px;

      ul {
        li {
          //padding: 6px 0px !important;

          img {
            width: 22px !important;
            height: 22px !important;
          }
        }
      }
      .Unsub_container {
        padding: 0px 15px;
        .Unsub_info {
          margin-top: 0%;
          .Unsub_btn {
            margin-top: 4%;
          }
        }
      }
    }
    .profile_container {
      .profile_data {
        p {
          margin: 0;
        }
        .profile_img {
          height: 27%;
          font-size: 13px;
          margin-bottom: 10%;
          img {
            width: 25% !important;
          }
        }
      }
      input {
        font-size: 15px !important;
      }
      .profile_menu {
        padding-left: 7%;
      }
    }
  }
}
</style>
