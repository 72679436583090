import { config } from "./domain-config";
import { Endpoints } from "../../endpoints";
import {
  PRODUCT_MOBIO_VR,
  COMPANY_SAM_MEDIA,
  COMPANY_TREND_TECH,
} from "./page-constants";

const terms = {
  [COMPANY_TREND_TECH]: {
    companyName: "TrendTech",
    ownerName: "TrendTech Ltd",
    ownerAddressLine1:
      "27 Old Gloucester Street, London WC1N 3AX, United Kingdom",
    ownerAddressLine2: "Old Gloucester London WC1N 3AX",
    owneremail: "uk.support@trend-tech.co.uk",
    lastUpdateDate: "July 23, 2021",
  },
  [COMPANY_SAM_MEDIA]: {
    companyName: "Sam Media",
    ownerName: "Sam Media BV",
    ownerAddressLine1: "Van Diemenstraat 140",
    ownerAddressLine2: "1013 CN Amsterdam",
    owneremail: "info@sam-media.com",
    lastUpdateDate: "May 08, 2020",
  },
};

// TODO: ALSO TAKE INTO ACCOUNT THE SUBDOMAIN HERE, TO LAUNCH MOBIOVR WITH SAM MEDIA FOR SOME OTHER CONNECTION
export const getTermsData = () =>
  config.productName === PRODUCT_MOBIO_VR
    ? terms[COMPANY_TREND_TECH]
    : terms[COMPANY_SAM_MEDIA];

export const getTermsText = async () => {
  const x = await document
    .querySelector("a-scene")
    .systems.data.fetch(Endpoints.termsPage());

  const termsPageLang = config.subdomain === "nl" ? "nl" : "en";

  return x[termsPageLang];
};
