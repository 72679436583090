//
const domainConfig = {
  demo: {
    country: "xx",
    lang: "en",
  },
  om: {
    country: "om",
    lang: "en",
  },
  ca: {
    country: "ca",
    lang: "en",
  },
  de: {
    country: "de",
    lang: "de",
  },
  za: {
    country: "za",
    lang: "en",
  },
  ps: {
    country: "ps",
    lang: "ar",
  },
  sa: {
    country: "sa",
    lang: "ar",
  },
  cz: {
    country: "cz",
    lang: "cz",
  },
  qa: {
    country: "qa",
    lang: "en",
  },
  nl: {
    country: "nl",
    lang: "nl",
  },
  co: {
    country: "co",
    lang: "es",
  },
  my: {
    country: "my",
    lang: "en",
  },
  rs: {
    country: "rs",
    lang: "rs",
  },
  th: {
    country: "th",
    lang: "th",
  },
  thtd: {
    country: "th",
    lang: "th",
  },
  es: {
    country: "es",
    lang: "es",
  },
  ee: {
    country: "ee",
    lang: "en",
  },
  id: {
    country: "id",
    lang: "en",
  },
  ae: {
    country: "ae",
    lang: "ar",
  },
  iq: {
    country: "iq",
    lang: "ar",
  },
  holozonia: {
    country: "xx",
    lang: "en",
  },
  kw: {
    country: "kw",
    lang: "ar",
  },
};

const loginPrimeHash = [
  {
    domain: "om.xr-academy.com",
    hash: "GOaZPw",
  },
  {
    domain: "demo.xr-academy.com",
    hash: "joBeP1",
  },
  {
    domain: "holozonia.xr-academy.com",
    hash: "joBeP1",
  },
  {
    domain: "ca.mobiovr.com",
    hash: "bOWVyG",
  },
  {
    domain: "de.xr-academy.com",
    hash: "vOKby0",
  },
  {
    domain: "za.xr-academy.com",
    hash: "0PjKo7",
  },
  {
    domain: "ps.xr-academy.com",
    hash: "VPY0O7",
  },
  {
    domain: "sa.xr-academy.com",
    hash: "WolWP1",
  },
  {
    domain: "cz.xr-academy.com",
    hash: "LPxaoJ",
  },
  {
    domain: "qa.xr-academy.com",
    hash: "KOq6PQ",
  },
  {
    domain: "nl.xr-academy.com",
    hash: "dOp3y1",
  },
  {
    domain: "co.mobiovr.com",
    hash: "2oQnyp",
  },
  {
    domain: "my.xr-academy.com",
    hash: "GPkZOg",
  },
  {
    domain: "rs.xr-academy.com",
    hash: "GPevox",
  },
  {
    domain: "th.xr-academy.com",
    hash: "4P7JOE",
  },
  {
    domain: "es.xr-academy.com",
    hash: "BOmbPN",
  },
  {
    domain: "thtd.xr-academy.com",
    hash: "pPdNy5",
  },
  {
    domain: "ee.xr-academy.com",
    hash: "VyXBPW",
  },
  {
    domain: "id.xr-academy.com",
    hash: "jO8YP2",
  },
  {
    domain: "sa.mobiovr.com",
    hash: "5y4woq",
  },
  {
    domain: "ae.mobiovr.com",
    hash: "bO6bOM",
  },
  {
    domain: "iq.xr-academy.com",
    hash: "qy3xO1",
  },
  {
    domain: "kw.mobiovr.com",
    hash: "6OGYo9",
  },
];

// STRINGS USED HERE HAS TO BE IN LINE WITH THE DOMAIN OF WEBAPP(eg: www.mobiovr.com)
export const PRODUCT_XR_ACADEMY = "xr-academy";
export const PRODUCT_MOBIO_VR = "mobiovr";
export const COMPANY_SAM_MEDIA = "SAM_MEDIA";
export const COMPANY_TREND_TECH = "TREND_TECH";

export { domainConfig, loginPrimeHash };
