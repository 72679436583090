export const Endpoints = {
  models: (model, category) => `/data/models/${category}/${model}.json`,
  menus: (category, submenu, model) => {
    if (model) {
      return `/data/models/${category}/${model}.json`;
    }
    return `/data/menus/${category}/${submenu ? submenu : category}.json`;
  },
  videos: (category) => `/data/videos/${category}.json`,
  generic: () => `/data/generic/generic.json`,
  termsPage: () => `/data/generic/terms-and-conditions.json`,
};
