import { errorHandler } from "./error-handler.utils";
import { getScene, getState } from "../../vue/utils/aframe.utils";

const assets = {};
let assetId = 0;
let elAssets;

export async function disposeAsset(src) {
  const asset = await assets[src];
  if (!asset) {
    return;
  }
  delete assets[src];

  THREE.Cache.remove(src);
}

function onError(asset, src, e, type) {
  if (asset.parentElement === elAssets) {
    elAssets.removeChild(asset);
  }
  delete assets[src];

  errorHandler(e, `Error while loading ${src}`, type);
}

function loadAssetItem(src) {
  return new Promise((resolve) => {
    const assetItem = document.createElement("a-asset-item");
    assetItem.setAttribute("crossorigin", "");
    assetItem.setAttribute("id", `asset-item-${assetId}`);
    assetItem.setAttribute("src", src);
    assetItem.addEventListener("error", (e) => {
      onError(assetItem, src, e, "3dmodel");
    });
    assetItem.addEventListener("loaded", () => resolve(assetItem));

    elAssets.appendChild(assetItem);
    assetId++;
  });
}

function loadImage(src) {
  return new Promise((resolve) => {
    const image = document.createElement("img");
    image.setAttribute("crossorigin", "");
    image.setAttribute("id", `asset-image-${assetId}`);
    image.addEventListener("error", (e) => onError(image, src, e, "image"));
    image.addEventListener("load", () => resolve(image));
    image.src = src;

    elAssets.appendChild(image);
    assetId++;
  });
}

function loadVideo(src) {
  return new Promise((resolve) => {
    const video = document.createElement("video");
    video.setAttribute("autoplay", "true");
    video.setAttribute("crossorigin", "");
    video.setAttribute("id", `asset-video-${assetId}`);
    video.setAttribute("muted", "muted");
    video.setAttribute("playsinline", "playsinline");
    video.setAttribute("webkit-playsinline", "webkit-playsinline");
    video.addEventListener("error", (e) => {
      onError(video, src, e, "video");
    });
    video.addEventListener("canplaythrough", () => resolve(video));
    video.setAttribute("src", src);

    elAssets.appendChild(video);
    assetId++;
  });
}

function getElAssets() {
  if (!elAssets) {
    elAssets = document.querySelector("a-assets");
  }

  return elAssets;
}

const fileExtensionRegex = /(?:\.([^.]+))?$/;

async function fetchAsset(url) {
  const response = await fetch(url);
  if (!response.ok) {
    throw response.status;
  }

  const blob = await response.blob();
  return URL.createObjectURL(blob);
}

export async function getAsset(url, data) {
  getElAssets();

  if (assets[url]) {
    return assets[url];
  }

  try {
    let promise;
    switch (fileExtensionRegex.exec(url)[1].toLowerCase()) {
      case "gltf":
      case "glb":
        promise = loadAssetItem(await fetchAsset(`${url}?${Math.random()}`));
        break;

      case "jpg":
      case "png":
        promise = loadImage(await fetchAsset(url));
        break;

      case "webm":
      case "mp4":
        promise = loadVideo(`${url}?${Math.random()}`);
        break;
    }

    if (data && data.showLoader) {
      const sceneEl = getScene();
      sceneEl.systems.loader.addIdForLoader(promise);
      promise.then(() => sceneEl.systems.loader.removeIdForLoader(promise));
    }

    assets[url] = promise;
    return promise;
  } catch (e) {
    if ([403, 404].includes(e) || getState().firstScene) {
      onError("asset", url, e);
      throw e;
    }

    console.log(e);
    // window.location.reload();
  }
}

export function getCanvas(id) {
  const canvasId = `canvas-${id}`;
  if (id && assets[canvasId]) {
    return { canvas: assets[canvasId], cached: true };
  }

  const canvas = document.createElement("canvas");
  canvas.setAttribute("class", id);
  canvas.setAttribute("crossorigin", "anonymous");
  canvas.setAttribute("id", `canvas-${assetId}`);

  const elAssets = getElAssets();
  elAssets.appendChild(canvas);
  // document.body.appendChild(canvas);
  assetId++;

  if (id) {
    assets[`canvas-${id}`] = canvas;
  }

  return { canvas, cached: false };
}
