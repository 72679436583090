import { config } from "./domain-config";

// To store auth data in WebStorage for persistance
export const webstore = {
  add: (data, rockmanId) => {
    if (localStorage.getItem("authorization"))
      localStorage.removeItem("authorization");
    const exp = Date.now() + 86400000; // 24 hours
    const d = encode(data);
    const payload = { e: exp, r: rockmanId, d };
    localStorage.setItem("authorization", JSON.stringify(payload));
  },
  get: () => {
    if (localStorage.getItem("authorization")) {
      const { e, r, d } = JSON.parse(localStorage.getItem("authorization"));
      if (new Date().getTime() > new Date(e).getTime()) {
        localStorage.removeItem("authorization");
        return null;
      }
      const data = decode(d);
      return { data, rockmanId: r };
    } else return null;
  },
  getProperty: (name) => {
    if (localStorage.getItem("authorization")) {
      const { e, d } = JSON.parse(localStorage.getItem("authorization"));
      if (new Date().getTime() > new Date(e).getTime()) {
        localStorage.removeItem("authorization");
        return "";
      }
      const data = decode(d);
      return data[name] || "";
    } else return "";
  },
  delete: () => {
    if (localStorage.getItem("authorization"))
      localStorage.removeItem("authorization");
  },
};

// To get UID from URL Search Params or WebStorage
export const getUid = () => {
  const uid =
    new URL(window.location.href).searchParams.get("uid") ||
    webstore.getProperty("token");
  return uid;
};

// To get MSISDN from URL Search Params
export const getMsisdn = () => {
  // eslint-disable-next-line max-len
  const msisdn =
    new URL(window.location.href).searchParams.get("m") ||
    new URL(window.location.href).searchParams.get("msisdn");
  return msisdn;
};

export const getLoginCode = () => {
  const loginCode = new URL(window.location.href).searchParams.get("loginCode");
  return loginCode;
};

// To get the base auth endpoint
export const getAuthEndpoint = () =>
  new URL(`https://login.${config.productName}.com/auth`);

export const encode = (obj) => btoa(JSON.stringify(obj));

export const decode = (data) => JSON.parse(atob(data));

export const getDomain = () => `${config.subdomain}.${config.productName}.com`;

export const getRockmanId = () => {
  const store = webstore.get();
  if (store) {
    const { rockmanId } = store;
    if (rockmanId && typeof rockmanId === "string" && rockmanId.length > 0)
      return rockmanId;
    return undefined;
  }
  return undefined;
};
