import {
  domainConfig,
  loginPrimeHash,
  PRODUCT_XR_ACADEMY,
  PRODUCT_MOBIO_VR,
} from "./page-constants";
import { environmentConfig } from "../../environments/environment";

const getSubdomain = () => {
  const host = window.location.hostname;
  return host.split(".")[0];
};

const getProductName = () => {
  const host = window.location.hostname;
  if (host.split(".")[1] === PRODUCT_MOBIO_VR) return PRODUCT_MOBIO_VR;
  return PRODUCT_XR_ACADEMY;
};

const getCustomerCareLink = () => {
  const host = window.location.hostname;
  const subdomain = getSubdomain();
  if (host.split(".")[1] === PRODUCT_MOBIO_VR)
    return `https://mobiovr.com/customer-care-${subdomain}`;
  return `https://xr-academy.com/customer-care-${subdomain}`;
};

const getHash = (subdomain, productName) => {
  const host = `${subdomain}.${productName}.com`;
  const idx = loginPrimeHash.findIndex((item) => item.domain === host);
  return loginPrimeHash[idx].hash;
};

const getConfigs = (subdomain, product) => {
  // Default Config in case it is not found
  let config = domainConfig["demo"];
  let hash = getHash("demo", PRODUCT_XR_ACADEMY);
  const subdomains = Object.keys(domainConfig);
  const productName = product || getProductName();
  const customerCareLink = getCustomerCareLink();

  if (subdomains.indexOf(subdomain) >= 0) {
    config = domainConfig[subdomain];
    hash = getHash(subdomain, productName);
  }

  const pageConfigs = {
    ...config,
    productName,
    subdomain,
    hash,
    customerCareLink,
  };
  return pageConfigs;
};

export let config = {};

// if (getSubdomain() === "localhost" || environmentConfig.production === false)
  config = getConfigs("demo", PRODUCT_XR_ACADEMY);
// else config = getConfigs(getSubdomain());
