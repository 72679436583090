import { Endpoints } from "../../endpoints";
import { generateButtonMenu } from "../utils/menu.utils";
import { addVueComponent, removeVueComponent } from "../utils/vue.utils";
import VideoMenuPreviousNextButtons from "../../vue/components/previous-next-buttons.component.vue";
import { getLookControls } from "../utils/camera.utils";
import { disposeObject3D, waitForLoaderToHide } from "../utils/scene.utils";
import {
  getState,
  transitionTo,
  getGenericData,
} from "../../vue/utils/aframe.utils";

AFRAME.registerComponent("generic-menu-scene-controller", {
  events: {
    sceneEnter: async function (e) {
      const { routeParameters } = e.detail;
      const { category, submenu = "", asset = "" } = routeParameters;

      this.el.sceneEl.emit("activateLookCamera", {
        animate: true,
        width: 10,
        height: 10,
        staticIfMobile: true,
        zoomIn: true,
      });

      const { menu, backScene } = await this.el.sceneEl.systems["data"].fetch(
        Endpoints.menus(`${category}`, `${submenu}`, `${asset}`)
      );

      if (asset !== "") {
        const { startExperienceText } = await getGenericData();

        this.elStartExperienceText.setAttribute("app-text", {
          align: "center",
          fontSize: 0.16,
          maxWidth: 3,
          value: startExperienceText,
        });
      }

      this.backScene = backScene;

      this.elHeader.setAttribute("generic-menu-header", {
        asset,
        category,
        enabled: true,
        submenu,
      });

      const buttons = menu.map((data) => ({
        disabled: !data.scene,
        image: data.thumbnail,
        scene: data.scene,
        title: data.thumbnail_text,
      }));

      const template = `#${!asset ? "genericMenuButton" : "viewAssetButton"}`;
      const buttonHeight = !asset ? 3.04 : 0.8;
      const buttonWidth = !asset ? 2 : 3.77;

      const { entities, rotations } = generateButtonMenu({
        buttons,
        buttonRotation: 30,
        buttonHeight,
        buttonWidth,
        template,
      });
      entities.forEach((elButton) =>
        elButton.addEventListener("click", this.onExperienceSelected)
      );
      this.rotations = rotations;
      this.selectedExperience = rotations.findIndex((search) => search === 0);
      this.rotateCamera(1);

      entities.forEach((entity) => this.elButtons.appendChild(entity));

      if (entities.length > 1) {
        this.vueButtons = addVueComponent(VideoMenuPreviousNextButtons);
        this.vueButtons.addEventListener("buttonPressed", this.onMoveCamera);
      }

      this.buttons = buttons;
      waitForLoaderToHide();
    },

    sceneExiting: function (e) {
      const { commands } = e.detail;

      const zoomIn = this.buttons
        .map((button) => button.scene)
        .some((scene) => scene === commands.join("/"));

      if (zoomIn) {
        this.el.sceneEl.emit("zoomCamera", {
          animate: true,
          width: 1,
          height: 1,
        });
      } else {
        this.el.sceneEl.emit("zoomCamera", {
          animate: true,
          width: 50,
          height: 50,
        });
      }

      if (this.elStartExperienceText.getAttribute("app-text", "value") !== "") {
        this.elStartExperienceText.setAttribute("app-text", "value", "");
      }

      this.el.sceneEl.emit("clearSceneInformation");

      if (this.vueButtons) {
        removeVueComponent(this.vueButtons);
      }
      this.vueButtons = null;
    },

    sceneExit: function () {
      if (this.elHeader) {
        this.elHeader.setAttribute("generic-menu-header", {
          enabled: false,
        });
      }
      Array.from(this.elButtons.children).forEach((elButton) => {
        disposeObject3D(elButton.object3D);

        elButton.removeEventListener("click", this.onExperienceSelected);
        elButton.parentElement.removeChild(elButton);
      });
    },

    goBack: function () {
      transitionTo(this.backScene.split("/"));
    },
  },

  update: function () {
    this.elButtons = this.el.querySelector(".buttons");
    this.elHeader = this.el.querySelector(".header");
    this.elSelector = this.el.querySelector(".cards-selector");
    this.elStartExperienceText = this.el.querySelector(
      ".start-experience-text"
    );
    this.onExperienceSelected = this.onExperienceSelected.bind(this);
    this.onMoveCamera = this.onMoveCamera.bind(this);
  },

  onExperienceSelected: function (e) {
    if (getState().userIsInVR) {
      return;
    }

    this.selectedExperience = e.target.getAttribute("data-index");
    this.rotateCamera(500);
  },

  onMoveCamera: function (e) {
    const move = e.detail;
    this.selectedExperience += move;

    if (this.selectedExperience < 0) {
      this.selectedExperience = this.rotations.length - 1;
    } else if (this.selectedExperience >= this.rotations.length) {
      this.selectedExperience = 0;
    }

    this.rotateCamera(500);
  },

  rotateCamera: function (dur) {
    if (!this.elSelector.object3D) {
      return;
    }

    const { radToDeg } = THREE.Math;
    const lookControls = getLookControls();
    const y = radToDeg(lookControls.object3D.rotation.y);

    const rotation = this.rotations[this.selectedExperience];
    const from = radToDeg(this.elSelector.object3D.rotation.y);
    const to = -rotation + y;

    this.elSelector.setAttribute("animation__rotation", {
      property: "object3D.rotation.y",
      easing: "linear",
      dur,
      from,
      to,
    });
  },
});
